<template>
  <div>
    <SlListView
      ref="listView"
      @gotoPage="gotoPage"
      :total="page.total"
      :pageIndex="page.pageIndex"
      :pageSize="page.pageSize"
    >
      <div slot="search">
        <!-- 搜索区域search包含搜索和重置按钮 -->
        <SlSearchForm
          ref="searchForm"
          v-model="query"
          :items="searchItems"
          :loading="tableLoading"
          @reset="gotoPage(page.pageSize)"
          @search="gotoPage(page.pageSize)"
        ></SlSearchForm>
      </div>
      <!-- 表格区域包含分页 -->
      <SlTable
        ref="table"
        :tableData="tableData"
        :columns="columns"
        :selection="false"
        :operate="false"
        :tooltip="false"
        align="left"
        rowKey="id"
      ></SlTable>
    </SlListView>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js'
import CommonUrl from '@api/url.js'
import OemGoodsAPI from '@api/oemGoods'

export default {
  name: 'CostReviewList',
  data () {
    return {
      tableLoading: false,
      tableData: [],
      page: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      query: {},
      searchItems: [
        {
          type: 'input',
          label: '生产订单号',
          name: 'purchaseOrderNumber'
        },
        {
          type: 'batch-input',
          label: 'SKU',
          name: 'skuCode'
        },
        {
          type: 'batch-input',
          label: 'UPC',
          name: 'upcs'
        },
        {
          type: 'input',
          label: '商品名称',
          name: 'name'
        },
        {
          type: 'single-select',
          label: '状态',
          name: 'status',
          data: {
            remoteUrl: CommonUrl.dictUrl,
            params: { dataCode: 'PURCHASE_PRICE_RECHECK_STATUS_ENUM' }
          }
        },
        {
          type: 'date',
          label: '申请日期',
          name: 'createdAts',
          data: {
            datetype: 'daterange',
            isBlock: true
          }
        }
      ],
      columns: [
        {
          prop: 'purchaseOrderNumber',
          label: '生产订单号',
          width: '120'
        },
        {
          prop: 'skuCode',
          label: 'SKU',
          width: '100'
        },
        {
          prop: 'upc',
          label: 'UPC',
          width: '100'
        },
        {
          prop: 'skuImage',
          label: '商品图片',
          isImg: true,
          data: {
            imgSize: '8rem'
          }
        },
        {
          prop: 'name',
          label: '商品名称',
          width: '225',
          render: (h, data) => {
            let { row = {} } = data
            return (
              <el-tooltip placement="top" effect="light">
                <div slot="content" style="max-width:300px">{row.name}</div>
                <p v-slClamp={{ clamp: 2 }}>{row.name}</p>
              </el-tooltip>
            )
          }
        },
        {
          prop: 'attributesName',
          label: '销售属性',
          width: '100'
        },
        {
          prop: 'originPrice',
          label: '原价'
        },
        {
          prop: 'newPrice',
          label: '修改后价格',
          width: '120'
        },
        {
          prop: 'priceRange',
          label: '价格变化',
          render: (h, data) => {
            let { row = {} } = data
            let num = Number(row.priceRange) // 转数字,去掉无效小数位
            let noShowPriceRange = row.priceRange === null || isNaN(num) || [0, 2].includes(row.status)
            return noShowPriceRange ? '' : (<el-link type={num >= 0 ? 'danger' : 'success'} underline={false} disabled>{num >= 0 ? `+${BigNumber(num).times(100)}%` : `${BigNumber(num).times(100)}%`}</el-link>)
          }
        },
        {
          prop: 'applyType',
          label: '复核原因',
          width: '200'
        },
        {
          prop: 'attachmentUrlList',
          label: '图片',
          render: (h, data) => {
            let { row = {} } = data
            if (!row.attachmentUrlList || row.attachmentUrlList.length === 0) {
              return <span>-</span>
            }
            return <SlPreviewImages images={row.attachmentUrlList} ></SlPreviewImages>
          }
        },
        {
          prop: 'applyRemark',
          label: '原因备注',
          width: '225',
          render: (h, data) => {
            let { row = {} } = data
            return (
              <el-tooltip placement="top" effect="light">
                <div slot="content" style="max-width:300px">{row.applyRemark}</div>
                <p v-slClamp={{ clamp: 2 }}>{row.applyRemark}</p>
              </el-tooltip>
            )
          }
        },
        {
          prop: 'auditRemark',
          label: '处理意见',
          width: '225',
          render: (h, data) => {
            let { row = {} } = data
            return (
              <el-tooltip placement="top" effect="light">
                <div slot="content" style="max-width:300px">{row.auditRemark}</div>
                <p v-slClamp={{ clamp: 2 }}>{row.auditRemark}</p>
              </el-tooltip>
            )
          }
        },
        {
          prop: 'statusName',
          label: '操作时间',
          width: '225',
          render: (h, data) => {
            let { row = {} } = data
            return (
              <div>
                {row.createdAt && <p>申请：{row.createdAt}</p>}
                {row.updatedAt && <p>处理：{row.updatedAt}</p>}
              </div>
            )
          }
        },
        {
          prop: 'statusName',
          label: '状态'
        }
      ]
    }
  },
  methods: {
    gotoPage (pageSize = 10, pageIndex = 1) {
      const params = this.generateParams(pageSize, pageIndex)
      this.tableLoading = true
      OemGoodsAPI.priceRecheckList(params).then(res => {
        let { success, data = {} } = res
        if (success) {
          this.tableData = data.list
          this.page.total = data.total
          this.page.pageIndex = pageIndex
          this.page.pageSize = pageSize
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    generateParams (pageSize, pageIndex) {
      return {
        ...this.getQureyParams(),
        pageIndex,
        pageSize
      }
    },
    getQureyParams () {
      let { createdAts = [], ...orther } = this.query
      return {
        ...orther,
        createdAtStart: createdAts && createdAts[0] ? createdAts[0] : '',
        createdAtEnd: createdAts && createdAts[1] ? createdAts[1] : ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
